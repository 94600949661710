.title {
    padding: 0 20px;
}

.contract-title {
    color: rgb(126 191 255);
    font-size: 30px;
}

.additional-wrapper {
    margin: 0;
    margin-block: 0;
    padding-inline-start: 20px;
    gap: 10px;
}