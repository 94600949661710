:root {
    --main-background: #1A1A1A;
    --header-color: #1A1A1A;

    --primary-blue:#045eb4;
    --primary-gray: #477cde;
}

#root,
.App {
    height: 100vh;
    overflow: hidden;
    color: white;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    z-index: 0;
}

.site-container {
    overflow-y: auto;
    height: 100%;
    background: var(--main-background);
    overflow-x: hidden;
}

.site-container.no-overflow {
    overflow: hidden;
    height: 100vh;
}

.header {
    padding: 16px;
}

.full-width-space {
    width: 100%;
}

.font-size-16 {
    font-size: 16px;
}

.clickable {
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
}

.max-width-1270 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    max-width: 1270px;
    font-size: 16px;
    margin: 20px;
    gap: 20px;
    width: calc(100% - 40px)
}

.flex {
    display: flex;
    align-items: center;
    gap: 16px;
}

.align-start {
    align-items: flex-start;
}

.flex-centered {
    gap: 16px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.row { 
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.fill-empty {
    flex: 1 1 auto;
}

.font-size-20 {
    font-size: 20px;
}

/* Webkit scroll bar */
::-webkit-scrollbar {
    -webkit-border-radius: 0 100px 0;
    width: 6px;
    height: 6px;
    background-color: transparent;
    border: none !important;
}

::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 100px;
    background: #2e2d2d;
    border-radius: 100px;
}

.link {
    color: rgb(88, 164, 240) !important;
    font-style: italic;
}

.font-size-24 {
    font-size: 24px;
}